.competitor-metrics-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
}

    .competitor-metrics-preview .competitor-metrics-container {
        display: flex;
        gap: 1rem;
        align-items: center;

        width: 100%;
    }

        .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 0.25rem;

            flex-grow: 1;
        }

            .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-left .competitor-metrics-progress-value {
                color: var(--color-warning);
                text-align: center;
                font-size: 0.6875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1rem;

                min-width: 32px;
            }

            .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-left .competitor-metrics-progress {
                background: linear-gradient(90deg, #FFBD07 0%, rgba(255, 189, 7, 0.00) 100%);

                height: 0.125rem;
            }

        .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-logo {
            border-radius: 50%;

            min-width: 2rem;
            height: 2rem;

            object-fit: cover;
            object-position: center;
        }

            .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-logo img {
                border-radius: 50%;

                width: 2rem;
                height: 100%;
            }

        .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-add-button {
            margin: 0 auto;
            border: 1px var(--color-success) solid;
            border-radius: 50%;

            min-width: 2rem;
            width: 2rem;
            height: 2rem;

            color: var(--color-success);

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.25rem;

            flex-grow: 1;
        }

            .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-right .competitor-metrics-progress-value {
                color: var(--color-danger);
                text-align: center;
                font-size: 0.6875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1rem;

                min-width: 32px;
            }

            .competitor-metrics-preview .competitor-metrics-container .competitor-metrics-right .competitor-metrics-progress {
                background: linear-gradient(90deg, rgba(255, 7, 12, 0.00) 0%, #FF070C 100%);

                height: 0.125rem;
            }

    .competitor-metrics-preview .competitor-metrics-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25rem;

        color: #333;
        text-align: center;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }

        .competitor-metrics-preview .competitor-metrics-title .subtitle {
            display: flex;
            flex-direction: column;
            align-items: center;

            color: #ACACAC;
            text-align: center;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }