:root {
    --bs-body-font-family: "Inter", Arial, sans-serif;

    --standard-gap: 1.5625rem;

    --bs-primary-rgb: 21, 101, 192;
    --bs-blue: rgba(var(--bs-primary-rgb), 1);
    --bs-primary: rgba(var(--bs-primary-rgb), 1);
    --color-primary: rgba(var(--bs-primary-rgb), 1);
    --primary: rgba(var(--bs-primary-rgb), 1);
    --primary-00: rgba(var(--bs-primary-rgb), 0);
    --primary-05: rgba(var(--bs-primary-rgb), 0.05);
    --primary-10: rgba(var(--bs-primary-rgb), 0.1);
    --primary-20: rgba(var(--bs-primary-rgb), 0.2);
    --primary-30: rgba(var(--bs-primary-rgb), 0.3);
    --primary-40: rgba(var(--bs-primary-rgb), 0.4);
    --primary-50: rgba(var(--bs-primary-rgb), 0.5);
    --primary-60: rgba(var(--bs-primary-rgb), 0.6);
    --primary-70: rgba(var(--bs-primary-rgb), 0.7);
    --primary-80: rgba(var(--bs-primary-rgb), 0.8);
    --primary-90: rgba(var(--bs-primary-rgb), 0.9);

    --bs-danger-rgb: 255, 10, 10;
    --color-danger: rgba(var(--bs-danger-rgb), 1);
    --danger: rgba(var(--bs-danger-rgb), 1);
    --danger-10: rgba(var(--bs-danger-rgb), 0.1);
    --danger-20: rgba(var(--bs-danger-rgb), 0.2);
    --danger-30: rgba(var(--bs-danger-rgb), 0.3);
    --danger-40: rgba(var(--bs-danger-rgb), 0.4);
    --danger-50: rgba(var(--bs-danger-rgb), 0.5);
    --danger-60: rgba(var(--bs-danger-rgb), 0.6);
    --danger-70: rgba(var(--bs-danger-rgb), 0.7);
    --danger-80: rgba(var(--bs-danger-rgb), 0.8);
    --danger-90: rgba(var(--bs-danger-rgb), 0.9);

    --bs-success-rgb: 15, 186, 0;
    --color-success: rgba(var(--bs-success-rgb), 1);
    --success: rgba(var(--bs-success-rgb), 1);
    --success-05: rgba(var(--bs-success-rgb), 0.05);
    --success-10: rgba(var(--bs-success-rgb), 0.1);
    --success-20: rgba(var(--bs-success-rgb), 0.2);
    --success-30: rgba(var(--bs-success-rgb), 0.3);
    --success-40: rgba(var(--bs-success-rgb), 0.4);
    --success-50: rgba(var(--bs-success-rgb), 0.5);
    --success-60: rgba(var(--bs-success-rgb), 0.6);
    --success-70: rgba(var(--bs-success-rgb), 0.7);
    --success-80: rgba(var(--bs-success-rgb), 0.8);
    --success-90: rgba(var(--bs-success-rgb), 0.9);

    --bs-warning-rgb: 255, 189, 7;
    --color-warning: rgba(var(--bs-warning-rgb), 1);

    --bs-link-color: rgba(var(--bs-primary-rgb), 1);
    --bs-link-color-rgb: var(--bs-primary-rgb);
    --bs-link-decoration: underline;
    --bs-link-hover-color-rgb: 81, 45, 168;
    --bs-link-hover-color: rgba(var(--bs-link-hover-color-rgb), 1);

    --bs-border-radius: 3px;
    --bs-border-radius-sm: 3px;
    --bs-border-radius-lg: 3px;
    --bs-border-radius-xl: 3px;
    --bs-border-radius-xxl: 3px;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
}

body {
    --bs-body-color: #333;
}

.p-st {padding: var(--standard-gap) !important;}
.px-st {padding-left: var(--standard-gap) !important; padding-right: var(--standard-gap) !important;}
.py-st {padding-top: var(--standard-gap) !important; padding-bottom: var(--standard-gap) !important;}
.pt-st {padding-top: var(--standard-gap) !important;}
.pb-st {padding-bottom: var(--standard-gap) !important;}
.ps-st {padding-left: var(--standard-gap) !important;}
.pe-st {padding-right: var(--standard-gap) !important;}

.p-double-st {padding: calc(var(--standard-gap) * 2) !important;}
.px-double-st {padding-left: calc(var(--standard-gap) * 2) !important; padding-right: calc(var(--standard-gap) * 2) !important;}
.py-double-st {padding-top: calc(var(--standard-gap) * 2) !important; padding-bottom: calc(var(--standard-gap) * 2) !important;}
.pt-double-st {padding-top: calc(var(--standard-gap) * 2) !important;}
.pb-double-st {padding-bottom: calc(var(--standard-gap) * 2) !important;}
.ps-double-st {padding-left: calc(var(--standard-gap) * 2) !important;}
.pe-double-st {padding-right: calc(var(--standard-gap) * 2) !important;}

.m-st {margin: var(--standard-gap) !important;}
.mx-st {margin-left: var(--standard-gap) !important; margin-right: var(--standard-gap) !important;}
.my-st {margin-top: var(--standard-gap) !important; margin-bottom: var(--standard-gap) !important;}
.mt-st {margin-top: var(--standard-gap) !important;}
.mb-st {margin-bottom: var(--standard-gap) !important;}
.ms-st {margin-left: var(--standard-gap) !important;}
.me-st {margin-right: var(--standard-gap) !important;}

.m-double-st {margin: calc(var(--standard-gap) * 2) !important;}
.mx-double-st {margin-left: calc(var(--standard-gap) * 2) !important; margin-right: calc(var(--standard-gap) * 2) !important;}
.my-double-st {margin-top: calc(var(--standard-gap) * 2) !important; margin-bottom: calc(var(--standard-gap) * 2) !important;}
.mt-double-st {margin-top: calc(var(--standard-gap) * 2) !important;}
.mb-double-st {margin-bottom: calc(var(--standard-gap) * 2) !important;}
.ms-double-st {margin-left: calc(var(--standard-gap) * 2) !important;}
.me-double-st {margin-right: calc(var(--standard-gap) * 2) !important;}


.card {
    --bs-card-spacer-y: 0;
    --bs-card-spacer-x: 0;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: #2A3547;
    --bs-card-subtitle-color: #2A3547;
    --bs-card-border-width: 0px;
    --bs-card-border-color: transparent;
    --bs-card-border-radius: 0;
    --bs-card-box-shadow: none;
    --bs-card-inner-border-radius: 0;
    --bs-card-cap-padding-y: 0;
    --bs-card-cap-padding-x: 0;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    color: var(--bs-body-color);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    box-shadow: var(--bs-card-box-shadow);
}

.btn-success {
    --bs-btn-bg: rgb(var(--bs-success-rgb));
    --bs-btn-border-color: var(--bs-btn-bg);
    --bs-btn-hover-bg: #0EAD00;
    --bs-btn-hover-border-color: #0DA100;
    --bs-btn-focus-shadow-rgb: 54, 227, 196;
    --bs-btn-active-bg: #0EAD00;
    --bs-btn-active-border-color: #0EAD00;
    --bs-btn-disabled-bg: var(--bs-btn-bg);
    --bs-btn-disabled-border-color: var(--bs-btn-bg);
}

.btn-outline-success {
    --bs-btn-color: #0EAD00;
    --bs-btn-border-color: #0EAD00;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0EAD00;
    --bs-btn-hover-border-color: #0EAD00;
    --bs-btn-focus-shadow-rgb: 19, 222, 185;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0EAD00;
    --bs-btn-active-border-color: #0EAD00;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0EAD00;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0EAD00;
    --bs-gradient: none;
}

.btn {
    --bs-btn-border-radius: 3px;
    --bs-btn-padding-x: 1.25rem;
    --bs-btn-padding-y: 0.4375rem;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1;
}