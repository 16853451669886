/*** MAP STYLES ***/

.map-panel {
    width: 100%;
    height: 100%;
    min-height: 600px;
    position: relative;
}

.map-panel.ruler-active #map-container {
    cursor: crosshair;
}

#map-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
}

.map-area {
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    pointer-events: none;

    position: absolute;
    z-index: 40;
}

.map-area > * {
    pointer-events: auto;
}

.map-area.map-corner-top-left {
    top: 0;
    left: 0;
}

.map-area.map-corner-top-right {
    top: 0;
    right: 0;
    align-items: end;
}

.map-area.map-corner-bottom-left {
    bottom: 0;
    left: 0;
    padding-bottom: 32px;
}

.map-area.map-corner-bottom-right {
    bottom: 0;
    right: 0;
    padding-bottom: 32px;
}

.map-area.map-top {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 30%;
}

.map-area.project-info {
    top: 0;
    left: calc(17.5% + 56px);
    transform: translate(calc(-50% - 16px), 0);
    width: 25%;
}

.map-area .shape-info-panel-container {
    pointer-events: none;
}

.map-area .shape-info-panel-container > * {
    pointer-events: auto;
}

.map-area .btn-group, .map-area .btn-group-vertical {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.icon-button {
    padding: 11px;
    border: 0;
    background: none;
    outline: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    display: none;

    color: #856404;
}

.onmap-info-panel:hover .icon-button {
    display: flex;
}

.icon-button.passive {
    padding: 0;
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    display: flex;
}

.form-control.map-info-control {
    padding: 0 !important;
    border: 0 !important;
    border-bottom: 1px dotted #856404 !important;
    border-radius: 0;
}

.form-control.map-info-control h1 sup {

}

.form-control.input-onmap {
    padding: 0 40px 0 12px;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    line-height: 40px;
    font-size: 14px;
    color: #5a6a85;

    height: 40px;
}

.form-control.input-onmap .rbt-input-main.form-control.rbt-input {
    margin: 0;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    box-shadow: none;

    height: auto;
}

.btn-onmap {
    padding: 0;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: #5a6a85;

    transition: background-color 0.3s, border-color 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px !important;
    height: 40px;
}

.btn-onmap:hover {
    background-color: #f4f7fb;
    border-color: #d0d0d0;
}

.btn-onmap:active, .btn-onmap.active, .btn-onmap:active:focus, .btn-onmap.active:focus {
    background-color: #f0f3f7;
    border-color: #c0c0c0;
}

.map-area .dropdown-menu {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    transform: translate(0, 48px);
    right: 0;
    top: 0;
    z-index: 120;
}

.map-area .dropdown-cover {
    background-color: transparent;
    content: "";

    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    z-index: 110;
}

.onmap-info-panel {
    padding: 6px 12px;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    line-height: 1.6;
    font-size: 14px;
    color: #272727;

    min-width: 200px;
    max-width: 300px;

    position: relative;
}

.onmap-info-panel.style-warning, .panel-style-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
}

.btn.panel-style-warning:hover {
    background-color: #ecd393;
    border-color: #dac281;
    color: #856404;
}

.map-area.project-info .onmap-info-panel {
    max-width: 100%;
}

.map-area.project-info .onmap-info-panel .icon-button {
    padding: 9px 11px;
    top: 0;
    transform: none;
}

.onmap-info-panel.map-info-panel {
    min-width: 825px;
}

.onmap-info-panel.map-info-panel.short-form {
    min-width: 250px;
}

.onmap-info-panel .btn-group.shape-toolbar {
    background-color: #fff3cd;
    box-shadow: none;
    width: 200px;
    float: right;
}

.onmap-info-panel .btn-group.shape-toolbar > .btn {
    flex: none
}

.editor-container .toolbar, .editor-container .editor-inner {
    background: none;
}