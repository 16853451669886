.publication-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(var(--standard-gap) * 2);
}

    .publication-edit .publication-edit-contents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: var(--standard-gap);

        width: 100%;
    }
    @media screen and (min-width: 992px) {
        .publication-edit .publication-edit-contents {
            flex-direction: row;
        }
    }

        .publication-edit .publication-edit-contents > * {
            flex-grow: 1;
            width: 100%;
        }

        .publication-edit .publication-edit-contents .publication-edit-contents-working-area {
            flex-direction: column;
            gap: var(--standard-gap);
        }
        @media screen and (min-width: 1300px) {
            .publication-edit .publication-edit-contents .publication-edit-contents-working-area {
                flex-direction: row-reverse;
            }
        }

            .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav {
                padding: 0 20px 0 20px;
                border-bottom: 1px solid #074EFF;

                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 2rem;

                width: 100%;
                position: relative;
            }
            @media screen and (min-width: 1300px) {
                .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav {
                    padding: 10px 5px 10px 0;
                    border-left: 1px solid #074EFF;
                    border-bottom: none;

                    flex-direction: column;
                    gap: 1rem;

                    width: auto;
                    min-height: 200px;
                }
            }
            .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav:after {
                content: '';
                background: linear-gradient(90deg, #074EFF 0, #074EFF 100px, #fff 100%);

                height: 1px;
                width: 100%;

                position: absolute;
                bottom: -1px;
                left: 0;
            }
            @media screen and (min-width: 1300px) {
                .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav:after {
                    background: linear-gradient(#074EFF 0, #074EFF 100px, #fff 100%);

                    height: 100%;
                    width: 1px;

                    top: 0;
                    bottom: auto;
                    left: -1px;
                }
            }

                .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav .nav-link {
                    border-bottom: 1px solid #fff;
                    padding: 0 5px 5px 5px;
                    border-radius: 0;
                }
                @media screen and (min-width: 1300px) {
                    .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav .nav-link {
                        border-left: 1px solid #fff;
                        border-bottom: none;
                        padding: 5px 0 5px 10px;
                    }
                }

                    .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav .nav-link svg {
                        stroke: var(--bs-body-color);

                        transition: stroke 0.3s;
                    }
                    .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav .nav-link:hover svg {
                        stroke: var(--color-primary);
                    }

                .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav .nav-link.active {
                    border-color: var(--color-primary);
                    background: none;
                }

                    .publication-edit .publication-edit-contents .publication-edit-contents-working-area > .nav .nav-link.active svg {
                        stroke: var(--color-primary);
                    }

            .publication-edit .publication-edit-contents .publication-edit-contents-working-area .tab-content {
                width: 100%;
            }

                .publication-edit .publication-edit-contents .publication-edit-contents-working-area .tab-content .tab-pane {
                    width: 100%;
                }

.crop-instagram-square {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    background-image: repeating-linear-gradient(
            45deg,
            transparent,
            transparent 10px,
            rgba(255,0,0,0.35) 10px,
            rgba(255,0,0,0.35) 20px
    );
    border: 1px solid rgba(255,0,0,0.5);
}
.crop-instagram-square.landscape-orientation {
    width: auto;
    height: 100%;
}

.crop-safe-zones {
    pointer-events: none;

    width: 100%;
    height: 100%;
    position: relative;
}

    .crop-safe-zones .safe-zone {
        background-image: repeating-linear-gradient(
                45deg,
                transparent,
                transparent 10px,
                rgba(255, 0, 0, 0.35) 10px,
                rgba(255, 0, 0, 0.35) 20px
        );
        border: 1px solid rgba(255, 0, 0, 0.5);
        position: absolute;
        left: 0;
        width: 100%;
        height: 13%;
    }

    .crop-safe-zones .safe-zone-top {
        top: 0;
    }

    .crop-safe-zones .safe-zone-bottom {
        bottom: 0;
    }

.video-preview-selector {

}

    .video-preview-selector > div {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 60vh;
        max-height: 60vh;
    }

    .video-preview-selector video {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
        max-height: 100%;
    }