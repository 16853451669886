#main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-fluid, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-lg, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-md, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-sm, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-xl, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-xxl {
    padding-top: 100px!important;
}

.business-form-map-cover {
    position: relative;
}

    .business-form-map {
        height: 300px;
        width: 100%;
    }

    .business-form-map-cover .on-map-toolbox {
        z-index: 1000;
        top: 16px;
        right: 16px;
        position: absolute;
    }

        .business-form-map-cover .on-map-toolbox .btn-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }

/*** Reviews Rules Stats ***/

table.reviews-rules-stats th, table.reviews-rules-stats td {
    text-align: center;
}

table.reviews-rules-stats td {
    cursor: pointer;
}

table.reviews-rules-stats tr td:nth-child(1) {
    width: 130px;
}

    table.reviews-rules-stats tr td:nth-child(1) div {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 5px;
    }

.business-media-nav {
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 10px;
}

    .business-media-nav a {
        white-space: nowrap;
        flex-grow: 1;
    }

.drop-area {
    padding: 20px 20px;
    border: 1px dotted rgba(0,0,0,.3);
    background: rgba(0,0,0,0.03);

    min-height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}
.drop-area.active {
    border-color: rgb(93, 135, 255);
    background: rgba(93, 135, 255,0.03);
    color: rgb(93, 135, 255);
}

.w-xl-100 {
    width: auto!important;
}

.app-header .navbar .navbar-nav .nav-item .nav-link {
    padding: 6px 16px 10px 16px;
}

aside.left-sidebar .fixed-profile {
    position: fixed;
    bottom: 15px;
}

.publication-media-preview-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
}
.publication-media-preview-container.stories-container {
    grid-template-columns: repeat(5, 1fr);
}

.publication-media-preview-container.disabled {
    max-height: 100px;
    overflow: hidden;
}
.publication-media-preview-container.disabled::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5), #ffffff);
    z-index: 100;
}

.publication-media-preview {
    display: flex;
    align-items: center;
    justify-content: center;

    aspect-ratio: 1/1;
    user-select: none;

    position: relative;
}
.publication-media-preview-container.stories-container .publication-media-preview {
    aspect-ratio: 9/16;
}

    .publication-media-preview .main-media {
        width: 100%;
        height: 100%;
        z-index: 1;
    }

        .publication-media-preview .main-media img {
            background-color: #eaeaea;
            background-image: repeating-linear-gradient(45deg, transparent, transparent 10px, #dadada 10px, #dadada 20px);

            width: 100%;
            height: 100%;

            object-fit: contain;
            object-position: 50% 0;
        }

    .publication-media-preview .video-icon {
        border-radius: 50%;
        border: 2px #ffffff solid;
        aspect-ratio: 1/1;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        bottom: 0;
        left: 50%;
        width: 24px;
        height: 24px;

        transform: translate(-50%, 50%);
        z-index: 50;
    }

    .publication-media-preview .media-toolbar {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        transition: background-color .3s, opacity .3s;

        z-index: 10;
    }
    .publication-media-preview .media-toolbar:hover {
        background-color: rgba(255,255,255,.3);
        opacity: 1;
    }

    .form-container {
        max-width: 800px;
    }

    .content-row {
        margin: 0.25rem 0;
        padding: 0.25rem 0;
        border-bottom: 1px solid var(--bs-light);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

        .content-row .content-row-title {

        }

        .content-row .content-row-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 0.5rem;
        }