@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import 'common.css';

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulseFade {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: var(--bs-body-color);
}

a.h1, a.h2, a.h3, a.h4, a.h5, a.h6 {
    color: var(--bs-link-color);
}
a.h1:hover, a.h2:hover, a.h3:hover, a.h4:hover, a.h5:hover, a.h6:hover {
    color: var(--bs-link-hover-color);
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 2.5rem;

    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.btn-round {
    border-radius: 1.25rem;
}

.btn-icon {
    padding-left: 0.4375rem;
    padding-right: 0.4375rem;
}

.btn-sm {
    padding-top: 0;
    padding-bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    height: 1.5625rem;
}

.btn-link {
    border-radius: 0;

    line-height: 1.2;
}
.btn-link-warning {
    color: rgb(var(--bs-warning-rgb));
}
.btn-link-warning:hover {
    color: var(--bs-warning-text-emphasis);
}

.btn-facebook {
    background-color: #3b5998;
    color: #fff;
}
.btn-facebook:hover {
    background-color: #2d4373;
    color: #fff;
}

.text-decoration-dashed {
    border-bottom: 1px dashed;

    text-decoration: none !important;
}
.text-decoration-dashed:hover {
    border-bottom: 1px dashed;
    text-decoration: none !important;
}

.simplebar-track.simplebar-vertical {
    width: 11px;
}

.simplebar-track.simplebar-horizontal {
    height: 4px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    top: 0;
    height: 4px;
}
.simplebar-scrollbar:before {
    top: 0;
}

a {
    color: var(--bs-link-color);
    transition: color 0.3s;
}
a:hover {
    color: var(--bs-link-hover-color);
}

.p-small-margins p {
    margin-bottom: 0.5rem;
}

.apexcharts-legend {
    padding: 0 !important;
}

span.apexcharts-legend-marker::after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background: #fff;
    border-radius: 100%;
    position: absolute;
}

.form-check-input {
    cursor: pointer;
}

.form-control, .form-select {
    border-radius: 3px;
}

.input-group-text {
    border-color: #dfe5ef;
    background-color: #dfe5ef;
}

.btn.btn-form-input {
    padding-left: 0.5625rem;
    padding-right: 0.5625rem;
    border-color: #dfe5ef;
    background-color: #dfe5ef;
}
.btn.btn-form-input:hover {
    border-color: #CED4DE;
    background-color: #CED4DE;
}

.form-switch, .form-switch label {
    cursor: pointer;
}

.datepicker-container {
    padding: 0.5rem;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

    bottom: -5px;
    right: 0;
    transform: translate(0, 100%);

    z-index: 10000;
    position: absolute;
}

    .datepicker-container .rdp-month_caption {
        padding: 0 0 0 1rem;
        text-transform: capitalize;
    }

    .datepicker-container .rdp-day {
        border-radius: 50%;

        transition: background 0.3s;
    }

    .datepicker-container .rdp-day:hover {
        background: var(--primary-10);
    }

h3, .h3 {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    color: #333;
}

h5, .h5 {
    margin: 0 0 0.25rem 0;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    color: #848484;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-on-hover {
    transition: background-color 0.3s;
}
.bg-on-hover:hover {
    background-color: var(--primary-05);
}

.badge {
    padding: 6px 15px;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    color: #333;

    display: flex;
    align-items: center;
    gap: 0.625rem;

    height: 2rem;
}
.badge-priority {
    background: rgba(255, 204, 0, 0.15);
    border-radius: 20px;

    color: #ECC800;
}
.badge-priority {
    background: rgba(255, 204, 0, 0.15);
    border-radius: 20px;

    color: #ECC800;
}
.badge-publication-entertainment {
    background: rgba(227, 0, 83, 0.10);
    border-radius: 20px;

    color: #E30053;
}
.badge-publication-educational {
    background: rgba(21, 101, 192, 0.10);
    border-radius: 20px;

    color: rgb(21, 101, 192);
}
.badge-publication-sales {
    background: rgba(0, 0, 0, 0.10);
    border-radius: 20px;

    color: rgb(0, 0, 0);
}
.badge-promoted {
    background: rgba(91, 194, 0, 0.10);
    border-radius: 20px;

    color: #5BC200;
}
.badge-primary {
    background: var(--primary);
    border-radius: 20px;

    color: var(--bs-white);
}
.badge-success {
    background: var(--success);
    border-radius: 20px;

    color: var(--bs-white);
}

.img-icon {
    width: 16px;
    height: auto;
}

.block-gap {
    gap: calc(var(--standard-gap) * 2);
}
.block-gap-single {
    gap: var(--standard-gap);
}
.block-gutter {
    --bs-gutter-x: calc(var(--standard-gap) * 2);
    --bs-gutter-y: calc(var(--standard-gap) * 2);
}
.block-gutter-lg {
    --bs-gutter-x: calc(var(--standard-gap) * 2);
    --bs-gutter-y: calc(var(--standard-gap) * 2);
}
@media (max-width: 992px) {
    .block-gutter-lg {
        --bs-gutter-x: 0;
        --bs-gutter-y: 0;
    }
}


/*** OLD THEME ***/


.m-block-gap {
    margin-bottom: 30px;
}

.blocks-divider-end {
    padding-right: 0;
    border-right: 0;
}

@media (min-width: 1300px) {
    .blocks-divider-end {
        padding-right: var(--bs-gutter-x);
        border-right: 1px solid var(--bs-border-color);
    }
}

.publication-card-media-container {
    background-color: var(--bs-light);
    border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0;

    width: 100%;
    height: 36px;

    overflow: hidden;
    position: relative;
}

    .publication-card-media-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
}

.card.card-sq-image a.sq-image {
    width: 100%;
    position: relative;
    display: block;
}
.card.card-sq-image a.sq-image::before {
    content: "";
    display: block;
    padding-top: 59%;
}

    .card.card-sq-image a.sq-image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

.card-add {
    height: calc(100% - 30px);
}

    .card-add i.ti {
        font-size: 120px;
    }

.one-liner {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    position: relative;
}
.one-liner::after {
    background: linear-gradient(to left, white, transparent);
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0.625rem;
    height: 100%;
}

.business-top-profile {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 200px;
}

.editable, .editable * {
    cursor: pointer !important;
}
.editable:hover {
    background-color: var(--bs-light);
}
.d-editable {visibility: hidden;}
.editable:hover .d-editable {visibility: visible;}

.bordered-tip {
    padding-left: 1.5rem;
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    border-bottom: 0;
}
@media screen and (max-width: 768px) {
    .bordered-tip {
        padding-left: 0;
        border-left: 0;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

table tr.row-passive {
    opacity: 0.3;

    transition: opacity 0.3s;
}
table tr.row-passive:hover {
    opacity: 1;
}

.table-primary {
    --bs-table-color: #ffffff;
    --bs-table-bg: rgb(93, 135, 255);
    --bs-table-active-bg: rgb(93, 135, 255);
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: rgb(93, 135, 255);
    --bs-table-hover-color: #ffffff;
}

.k-simple-form {
    max-width: 800px;
}

.image-upload-thumbnail {
    width: 64px;
    height: 64px;
}

    .image-upload-thumbnail img, .image-upload-thumbnail video {
        width: 64px;
        height: 64px;

        object-fit: contain;
        object-position: 50% 50%;
    }

.card.loading table {
    animation: pulseFade 2s ease-in-out infinite;
}

.card.loading::after {
    content: "";
    background-color: rgba(255,255,255,0.75);

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    position: absolute;
}

.card.loading .card-loading {
    margin: -16px 0 0 -16px;

    width: 32px;
    height: 32px;

    animation: spin 2s linear infinite;

    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 10;
}

    .card.loading .card-loading svg {
        width: 100%;
        height: 100%;
    }

        .card.loading .card-loading svg path {
            fill: var(--bs-primary);
        }

.card .project-logo {
    border: 3px #fff solid;
    border-radius: 35px;

    width: 70px;
    height: 70px;
    display: block;

    object-fit: cover;
    object-position: center;

    left: 14px;
    bottom: -35px;
    position: absolute;

    overflow: hidden;
}


.modal-content.loading::after {
    content: "";
    background-color: rgba(255,255,255,0.75);

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    position: absolute;

    z-index: 5;
}

.modal-content.loading .card-loading {
    margin: -16px 0 0 -16px;

    width: 32px;
    height: 32px;

    animation: spin 2s linear infinite;

    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 10;
}

    .modal-content.loading .card-loading svg {
        width: 100%;
        height: 100%;
    }

        .modal-content.loading .card-loading svg path {
            fill: var(--bs-primary);
        }




/*******************************/

/*** ------ NEW THEME ------ ***/

/*******************************/

.body-wrapper {
    margin-left: 130px;
    padding: 0 var(--standard-gap);
    min-height: calc(100vh - 100px - 60px);
}

@media (max-width: 992px) {
    .body-wrapper {
        margin-left: 0;
        padding: var(--standard-gap) var(--standard-gap) 0 var(--standard-gap);

        min-height: calc(100vh - 100px - 40px - 60px);
    }

}


header {
    padding: var(--standard-gap);

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100px;

    position: relative;

    z-index: 10;
}


    header .header-logo img {
        height: 42px;
    }


    .header-search {
        padding: 0 20px;
        border-radius: 20px;
        background-color: #f0f0f0;

        color: #333;

        width: 20%;
        height: 40px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        transition: width 0.3s, margin-left 0.3s;
    }
    .header-search.active {
        margin-left: 10%;
        width: 30%;
    }

        .header-search input[type="text"] {
            padding: 0.625rem 0;
            border: 0;
            background-color: transparent;

            color: #333;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;

            width: 100%;
        }

        .header-search button.btn.btn-search {
            margin: 0 -15px 0 0;
            padding: 0.625rem;
            border: none !important;

            line-height: 1;
        }

        .header-search.active button.btn.btn-search {
            color: var(--primary);
        }

    .header-search-mobile {
        margin: 0 calc(var(--standard-gap) / 2);
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.15);

        width: calc(100% - var(--standard-gap));
        height: 64px;

        display: flex;

        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(0, 100%);
        z-index: 1000;
    }

        .header-search-mobile input[type="text"] {
            padding: 0 calc(var(--standard-gap) / 2);
            border: 0;
            background-color: transparent;

            color: #333;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;

            width: 100%;
        }

        .header-search-mobile button.btn.btn-search {
            margin: 0 0 0 0;
            padding: 0 calc(var(--standard-gap));
            border: none !important;

            line-height: 1;
        }

        .header-search-mobile.active button.btn.btn-search {
            color: var(--primary);
        }


    #header-profile-label {
        display: flex;
        justify-content: stretch;
        align-items: center;

        gap: 0.625rem;

        cursor: pointer;
    }

        #header-profile-label .header-profile-image {
            padding: 2px;
            border: 2px solid transparent;
            border-radius: 50%;
        }
        #header-profile-label .header-profile-image.active {
            border-color: var(--danger);
        }

            #header-profile-label .header-profile-image img {
                border-radius: 50%;

                width: 36px;
                height: 36px;
            }

        #header-profile-label .header-profile-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            gap: 3px;
        }

            #header-profile-label .header-profile-name .header-profile-title {
                margin: 0;
                padding: 0;

                display: flex;
                justify-content: flex-end;
                align-items: center;

                gap: 0.625rem;
            }

                #header-profile-label .header-profile-name .header-profile-title h6 {
                    margin: 0;
                    padding: 0;

                    color: #333;
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    white-space: nowrap;

                    transition: color 0.3s;
                }

                #header-profile-label .header-profile-name .header-profile-title svg {
                    margin: 0;
                    padding: 0;

                    color: var(--primary);
                }

            #header-profile-label .header-profile-name > span {
                margin: 0;
                padding: 0;

                color: #848484;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }


.general-nav {
    padding: 0 var(--standard-gap);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 130px;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 5;
}

@media (max-width: 992px) {
    .general-nav {
        background-color: rgba(0, 0, 0, 0.03);

        justify-content: space-between;

        height: 40px;
        width: 100%;

        position: relative;
    }
}

    .general-nav .nav-item-cover {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        position: relative;
    }
    .general-nav .nav-item-cover::after {
        background: linear-gradient(180deg, var(--primary-00) 0%, var(--primary) 50%, var(--primary-00) 100%);

        content: "";

        opacity: 0.1;

        width: 1px;
        height: 100%;

        position: absolute;
        top: 0;
        right: -1.5625rem;
    }

    @media (max-width: 992px) {
        .general-nav .nav-item-cover {
            flex-direction: row;
            justify-content: flex-start;
            gap: 35px;

            width: 100%;
        }
        .general-nav .nav-item-cover::after {
            display: none;
        }
    }

        .general-nav .nav-item {
            padding: 0;
            border-radius: 50%;

            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            text-align: center;

            width: 80px;
            height: 80px;

            display: flex;
            justify-content: center;
            align-items: center;

            position: relative;
        }
        .general-nav .nav-item:after {
            border-radius: 50%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);

            content: "";
            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;

            width: 80px;
            height: 80px;

            transition: opacity 0.3s;

            z-index: -1;
        }
        .general-nav .nav-item:hover:after, .general-nav .nav-item.active:after {
            opacity: 0.04;
        }
        .general-nav .nav-item.active:after {
            background: linear-gradient(180deg, var(--primary-00) 0%, var(--primary) 100%);
        }

        @media (max-width: 992px) {
            .general-nav .nav-item-cover .nav-item {
                border-radius: 0;

                width: auto;
                height: 40px;
            }
            .general-nav .nav-item:after {
                border-radius: 0;
                background: rgba(0, 0, 0);

                width: 100%;
                height: 40px;

                display: none;
            }
            .general-nav .nav-item.active:after {
                background: var(--primary-00);
            }
        }

            .general-nav .nav-item a {
                color: #333;

                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .general-nav .nav-item.active a {
                color: var(--primary);
            }

            @media (max-width: 992px) {
                .general-nav .nav-item-cover .nav-item a {
                    flex-direction: row;
                }
            }

                .general-nav .nav-item a svg {
                    margin: 0 0 5px 0;
                }

                @media (max-width: 992px) {
                    .general-nav .nav-item-cover .nav-item a svg {
                        margin: 0 8px 0 0;
                    }
                }


footer {
    padding: var(--standard-gap);

    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;
}


nav.section-nav {
    margin: 0 0 calc(var(--standard-gap) * 2) 0;
    padding: var(--standard-gap) 0 0 0;
}

    nav.section-nav .top-level {
        margin: 0 0 0.625rem 0;
        padding: 0 0 0.625rem 0;

        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #333;

        overflow-y: hidden;
    }
    nav.section-nav .top-level::after {
        background: linear-gradient(to right, transparent, white);

        content: "";

        position: absolute;
        top: 0;
        right: 0;

        width: 0.625rem;
        height: 100%;
    }

        nav.section-nav .top-level .top-level-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 50px;
        }

            nav.section-nav .top-level .top-level-content a {
                color: #DADADA;
                white-space: nowrap;

                display: block;
            }
            nav.section-nav .top-level .top-level-content a.active {
                color: #333;
            }
            nav.section-nav .top-level .top-level-content a:last-child {
                padding: 0 0.625rem 0 0;
            }
            nav.section-nav .top-level .top-level-content a:not(.active):hover {
                color: var(--primary);
            }

    nav.section-nav .section-description {
        padding: 0 0 0 40px;

        position: relative;
    }
    nav.section-nav .section-description::before {
        border: 1px dotted #ACACAC;
        border-right: none;
        border-top: none;
        border-radius: 0 0 0 100%;

        content: "";

        width: 30px;
        height: 30px;

        position: absolute;
        top: -17px;
        left: 4px;
    }

        nav.section-nav .section-description h2 {
            margin: 0 0 5px 0;

            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #333;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 40px;
        }
        @media (max-width: 992px) {
            nav.section-nav .section-description h2 {
                justify-content: space-between;
                gap: 0;
            }
        }

            nav.section-nav .section-description h2 .section-text {
                white-space: nowrap;
            }

            nav.section-nav .section-description .section-toolbar {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
            }

                nav.section-nav .section-description .section-toolbar a {
                    color: #333;
                }
                nav.section-nav .section-description .section-toolbar a:hover {
                    color: var(--primary);
                }

        nav.section-nav .section-description .section-subtitle {
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #848484;
        }


.line-chart-noted {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 0;

    max-width: 500px;
}
.line-chart-noted.full-width {
    max-width: 20000px;
}
@media (max-width: 992px) {
    .line-chart-noted {
        margin: 0 -0.625rem;

        display: none;
    }
    .line-chart-noted.active {
        display: flex;
    }
}

    .line-chart-noted-title {
        padding: 0 0 0 32px;
    }

        .line-chart-noted-title h3 {
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #333;

            width: 100%;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

            .line-chart-noted-title h3 .title-text {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 0.625rem;
            }

            .line-chart-noted-title h3 .title-tools {
                display: none;
                justify-content: flex-end;
                align-items: center;
                gap: 2px;
            }
            @media (max-width: 992px) {
                .line-chart-noted-title h3 .title-tools {
                    display: flex;
                }
            }

        .line-chart-noted-title p.chart-description {
            margin: 0;

            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #848484;
        }