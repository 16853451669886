.publication-edit-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    gap: 1rem;

    width: 100%;
}
@media screen and (min-width: 992px) {
    .publication-edit-header {
        flex-direction: row;
        gap: var(--standard-gap);
    }
}

    .publication-edit-header .back-button-container {
        width: calc(5rem - var(--standard-gap));
    }

    .publication-edit-header .header-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: calc(2 * var(--standard-gap));
        flex-grow: 1;

        width: 100%;
    }
    @media screen and (min-width: 992px) {
        .publication-edit-header .header-container {
            flex-direction: row;
            gap: calc(2 * var(--standard-gap));
        }
    }

        .publication-edit-header .header-container .publication-info-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: var(--standard-gap);
            flex-grow: 1;
        }

            .publication-edit-header .header-container .publication-info-container .publication-info-container-top {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: calc(var(--standard-gap) / 5 * 3);
            }

                .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-title {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: calc(var(--standard-gap) / 5);
                }

                    .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-title .publication-info-subheader {
                        margin: 0;

                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0.5rem;
                    }

                    .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-title .publication-info-theme {
                        margin: 0;

                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: flex-start;
                        gap: 2.5rem;
                    }

                        .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-title .publication-info-theme > span {
                            line-height: 1.4;
                        }

                        .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-title .publication-info-theme .publication-info-theme-toolbar {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: calc(var(--standard-gap) / 5 * 4);
                        }

                            .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-title .publication-info-theme .publication-info-theme-toolbar a {
                                color: #333;
                            }
                            .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-title .publication-info-theme .publication-info-theme-toolbar a:hover {
                                color: var(--primary);
                            }

                    .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-title .publication-info-date {
                        font-size: 0.8125rem;
                    }

                .publication-edit-header .header-container .publication-info-container .publication-info-container-top .publication-info-badges {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: calc(var(--standard-gap) / 5 * 2);
                    flex-wrap: wrap;
                }

        .publication-edit-header .header-container .warnings-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            width: 100%;
            max-width: 400px;
        }
        @media screen and (min-width: 992px) {
            .publication-edit-header .header-container .warnings-container {
                width: 30%;
            }
        }

.publication-card-progress {

}

    .publication-card-progress .progress-bar {
        transition: width 0.5s;
    }