.publications-list-tabled {
    padding: var(--standard-gap) 0 calc(var(--standard-gap) * 2) 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 0.625rem;

    width: 100%;
}
@media (max-width: 992px) {
    .publications-list-tabled {
        padding-top: 0;
    }
}

    .publications-list-tabled .custom-responsive-behavior {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: stretch;

        width: 100%;
    }

    @media (max-width: 992px) {
        .publications-list-tabled .custom-responsive-behavior {
            flex-direction: column;

            gap: var(--standard-gap);
        }
    }

        .publications-list-tabled .custom-responsive-behavior > div:nth-child(1) {
            /*background-color: rgba(255,0,0,0.2);*/
            padding-right: calc(var(--standard-gap) * 2);

            width: 35%;
        }
        .publications-list-tabled .custom-responsive-behavior > div:nth-child(2) {
            /*background-color: rgba(0,255,0,0.2);*/

            display: flex;
            flex-direction: column;
            gap: 0.625rem;

            width: 65%;
        }

        @media (max-width: 992px) {
            .publications-list-tabled .custom-responsive-behavior > div:nth-child(1) {
                padding-right: 0;

                width: 100%;
            }
            .publications-list-tabled .custom-responsive-behavior > div:nth-child(2) {
                width: 100%;
            }
        }

    .publications-list-tabled h5 {
        margin: 0;

        font-size: 0.6875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;

        color: #848484;
    }

    .publications-list-tabled .publications-list-tabled-publication {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;

        width: 100%;
    }

        .publications-list-tabled .publications-list-tabled-publication .pub-date {
            padding: 0 0 0.625rem 0;

            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            color: #848484;
        }

        @media (max-width: 992px) {
            .publications-list-tabled .publications-list-tabled-publication .pub-date {
                padding: 40px 0 0.3125rem 0;
            }
        }

        .publications-list-tabled .publications-list-tabled-publication h2 {
            margin: 0;
            padding: 0;

            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            color: #333;
        }

        .publications-list-tabled .publications-list-tabled-publication .pub-characteristics {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 0.625rem;
        }

            .publications-list-tabled .publications-list-tabled-publication .pub-characteristics > div {
                margin: 0 0.625rem 0 0;
            }
            .publications-list-tabled .publications-list-tabled-publication .pub-characteristics > div:last-child {
                margin-right: 0;
            }

    .publications-list-tabled .publications-list-tabled-platforms {
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1;

        color: #333;

        display: flex;
        justify-content: space-between;
        gap: var(--standard-gap);

        width: 100%;
    }

    @media (max-width: 992px) {
        .publications-list-tabled .publications-list-tabled-platforms {
            font-size: 0.6875rem;

            gap: 0.625rem;
        }
    }

        .publications-list-tabled .publications-list-tabled-platforms > * {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex: 1;

            white-space: nowrap;
        }
        .publications-list-tabled .publications-list-tabled-platforms > *:nth-child(1) {
            max-width: 2.5rem;
        }
        @media (max-width: 992px) {
            .publications-list-tabled .publications-list-tabled-platforms > *:nth-child(1) {
                max-width: 1rem;
            }
            .publications-list-tabled .publications-list-tabled-platforms > *:nth-child(2) {
                display: none;
            }
            .publications-list-tabled .publications-list-tabled-platforms > *:nth-child(4) {
                max-width: 2.5rem;
            }
        }
        .publications-list-tabled .publications-list-tabled-platforms > *:last-child {
            min-width: 12.5rem;
            justify-content: flex-end;
        }
        @media (max-width: 992px) {
            .publications-list-tabled .publications-list-tabled-platforms > *:last-child {
                max-width: 4.25rem;
                min-width: auto;
            }
        }

        .publications-list-tabled .publications-list-tabled-platforms > *.status-field {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 0.3125rem;

            min-width: 5rem;
        }
        @media (max-width: 992px) {
            .publications-list-tabled .publications-list-tabled-platforms > *.status-field {
                min-width: 5rem;
            }
        }