.project-card {
    padding: 1rem;
    border-radius: 3px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    width: 100%;
    min-width: 400px;

    transition: background-color 0.3s;
    cursor: pointer;
}
@media (min-width: 768px) {
    .project-card {
        width: 50%;
    }
}

.project-card:hover {
    background-color: var(--success-05);
}

    .project-card .shown-on-hover {
        opacity: 0;

        transition: opacity 0.3s;
    }
    .project-card:hover .shown-on-hover {
        opacity: 1;
    }
