.publication-edit-status {
    --container-gap: calc(100% - 50rem) / 3;

    margin: 0 auto 0 0;
    padding: 1rem 0;
    background: linear-gradient(180deg,
        #FFF 0%,
        #CCC 20%,
        #FFBD07 40%,
        #074EFF 60%,
        #0FBA00 80%,
        #0FBA00 100%
    );

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;

    width: 2px;
    height: 200px;

    position: relative;
}
@media screen and (min-width: 768px) {
    .publication-edit-status {
        margin: 0.5rem 0 2.5rem 0;
        padding: 0 2rem;
        background: linear-gradient(90deg,
            #FFF 0%,
            #CCC 10rem,
            #FFBD07 calc(var(--container-gap) + 20rem),
            #074EFF calc(2 * var(--container-gap) + 30rem),
            #0FBA00 calc(3 * var(--container-gap) + 40rem),
            #0FBA00 100%
        );

        flex-direction: row;

        width: 100%;
        min-width: 50rem;
        height: 2px;
    }
}
@media screen and (min-width: 1300px) {
    .publication-edit-status {
        padding: 0 5rem;
    }
}

.publication-edit-status .status-progress-vanish {
    background: linear-gradient(180deg, rgba(234, 234, 234, 0.00) 0%, #EAEAEA 25%, #EAEAEA 100%);

    content: '';

    display: block;

    position: absolute;
    bottom: 0;
    right: 0;

    width: 2px;
    min-height: 2.5rem;
}
@media screen and (min-width: 768px) {
    .publication-edit-status .status-progress-vanish {
        background: linear-gradient(90deg, rgba(234, 234, 234, 0.00) 0%, #EAEAEA 25%, #EAEAEA 100%);

        width: auto;
        height: 2px;
        min-height: auto;
        min-width: 20rem;

        bottom: auto;
        top: 0;
    }
}
.publication-edit-status .status-progress-vanish.status-draft {height: 75%;}
.publication-edit-status .status-progress-vanish.status-under-review {height: 55%;}
.publication-edit-status .status-progress-vanish.status-scheduled {height: 35%;}
.publication-edit-status .status-progress-vanish.status-published {height: 15%;}
@media screen and (min-width: 768px) {
    .publication-edit-status .status-progress-vanish.status-draft {height: 100%; width: calc(100% - 5rem);}
    .publication-edit-status .status-progress-vanish.status-under-review {height: 100%; width: calc(2 * var(--container-gap) + 25rem);}
    .publication-edit-status .status-progress-vanish.status-scheduled {height: 100%; width: calc(var(--container-gap) + 15rem);}
    .publication-edit-status .status-progress-vanish.status-published {height: 100%; width: 15rem;}
}

    .publication-edit-status .status-progress-container {
        margin: 0 0 0 calc(-0.5rem - 1px);

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;

        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #333;
        white-space: nowrap;

        opacity: 0.25;

        width: auto;
    }
    @media screen and (min-width: 768px) {
        .publication-edit-status .status-progress-container {
            margin: calc(-0.5rem - 1px) 0 0 0;

            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.375rem;

            width: 10rem;
        }
    }
    .publication-edit-status .status-progress-container.active {
        opacity: 1;
    }

        .publication-edit-status .status-progress-container .title-text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.625rem;
        }

        .publication-edit-status .status-progress-container .status-icon {
            border: 2px solid #000;
            border-radius: 50%;
            background-color: #fff;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            width: calc(1rem + 2 * 2px);
            height: calc(1rem + 2 * 2px);

            visibility: hidden;
        }
        .publication-edit-status .status-progress-container.active .status-icon {
            visibility: visible;
        }
        .publication-edit-status .status-progress-container.current .status-icon {
            background-color: #fff !important;
        }

            .publication-edit-status .status-progress-container .status-icon svg {
                stroke: #fff;
            }
            .publication-edit-status .status-progress-container.current .status-icon svg {
                display: none;
            }

        .publication-edit-status .status-progress-container .status-icon.status-progress-draft {
            border-color: #ccc;
            background-color: #ccc;
        }

        .publication-edit-status .status-progress-container .status-icon.status-progress-under-review {
            border-color: #FFBD07;
            background-color: #FFBD07;
        }

        .publication-edit-status .status-progress-container .status-icon.status-progress-scheduled {
            border-color: #074EFF;
            background-color: #074EFF;
        }

        .publication-edit-status .status-progress-container .status-icon.status-progress-published {
            border-color: #0FBA00;
            background-color: #0FBA00;
        }