#publication-edit-caption {
}
@media screen and (min-width: 1300px) {
    #publication-edit-caption {
    }
}

.emoji-picker-container {
    box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;

    position: fixed;
    bottom: 1rem;
    right: 1rem;
}