.publication-edit-preview {
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 320px;
}
@media screen and (min-width: 992px) {
    .publication-edit-preview {
    }
}

    .publication-edit-preview .slick-prev {
        left: 16px !important;
        z-index: 1;
    }
    .publication-edit-preview .slick-next {
        right: 16px !important;
        z-index: 1;
    }

    .publication-edit-preview-caption {
        margin: 0.5rem 0 0 0;
        font-size: 0.8125rem;

        width: 100%;
    }

        .publication-edit-preview-caption-short {

        }

            .publication-edit-preview-caption-short > div {
                display: inline;
            }

.instagram-slider-item {
    background-color: #333;
    background-image: repeating-linear-gradient(
            45deg,
            transparent,
            transparent 10px,
            rgb(255, 202, 40) 10px,
            rgb(255, 202, 40) 20px
    );
    object-fit: contain;
    object-position: center;

    display: block !important;
}
.instagram-slider-item.ar-9-16 {
    aspect-ratio: 9/16;
}

    .instagram-slider-item img, .instagram-slider-item video {
        width: 100%;

        display: block;
    }


/************************************************************
 *******************  Facebook Collage  *********************
 ************************************************************/

 .main-item, .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    display: block;
}

.image-item {
    position: relative;
    overflow: hidden;
}

.image-item.last-image:hover .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 20px;
    text-align: center;
    visibility: visible;
    opacity: 1;
}

.overlay {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out;
}

