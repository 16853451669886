.project-title-extended {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;

    gap: 0.75rem;
}

    .project-title-extended .project-title-image {
        padding: 2px;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    .project-title-extended .project-title-image.active {
        border-color: var(--danger);
    }

        .project-title-extended .project-title-image img {
            border-radius: 50%;

            width: 72px;
            height: 72px;
        }

    .project-title-extended .project-title-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        gap: 0.6875rem;
    }

    .project-title-extended .project-title-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        gap: 3px;
    }

        .project-title-extended .project-title-name .project-title-heading {
            margin: 0;
            padding: 0;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 2.5rem;
        }

            .project-title-extended .project-title-name .project-title-heading h6 {
                margin: 0;
                padding: 0;

                color: #333;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;

                transition: color 0.3s;
            }

            .project-title-extended .project-title-name .project-title-heading .project-title-actions {
                margin: 0;
                padding: 0;

                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 1.25rem;
            }

                .project-title-extended .project-title-name .project-title-heading .project-title-actions svg {
                    margin: 0;
                    padding: 0;

                    color: #333;
                    transition: color 0.3s;
                }
                .project-title-extended .project-title-name .project-title-heading .project-title-actions svg:hover {
                    color: var(--primary);
                }

        .project-title-extended .project-title-name > span {
            margin: 0;
            padding: 0;

            color: #848484;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }

        .project-title-extended .project-title-links {
            margin: 0;
            padding: 0;

            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.9375rem;
        }

            .project-title-extended .project-title-links a {
                text-decoration: underline;
            }