.progress-bar-stepped {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 4px;

    width: 100%;

    position: relative;
}

    .progress-bar-stepped__step {
        background-color: #D9D9D9;

        width: 100%;
        height: 3px;
    }
    .progress-bar-stepped__step--active {
        background-color: rgb(var(--bs-success-rgb));
    }

    .progress-bar-stepped-label {
        padding: 0 0 0 0.5rem;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        color: #333;
    }